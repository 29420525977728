.right {
    position: absolute;
    top: 50%;
    transform : translate(0%, -50%);
    right: 0;
    width: 100%;
}

.left {
    position: absolute;
    top: 50%;
    transform : translate(0%, -50%);
    left: 0;
    width: 100%;
}

.bottomleft {
    position: absolute;
    text-align : left;
    bottom: 0;
    left: 0;
    width: 100%;
}

.topleft {
    position: absolute;
    transform : translate(0%, -100%);
    text-align : left;
    top: 50%;
    left: 0;
    width: 100%;
}

.topright {
    position: absolute;
    transform : translate(0%, -100%);
    text-align : right;
    top: 50%;
    right: 0;
    width: 100%;
}

.bottomright {
    position: absolute;
    text-align: right;
    bottom: 0;
    right: 0;
    width: 100%;
}

.center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform : translate(-50%, -50%);
    width: 100%;
}
